<template>
  <div style="min-height: 500px;" class="collectionNotImg">
    <div v-if="goodsList&&goodsList.length">
      <div class="table">
        <div class="tableHeader m-t-25">
          <div class="cell-1">
          </div>
          <div class="cell-2">商品信息</div>
          <div class="cell-3">价格</div>
          <div class="cell-4">收藏人数</div>
          <div class="cell-5">操作</div>
        </div>
        <div class="table-item" v-for="(item) in goodsList" :key="item.id">
          <div class="table-boby m-t-25">
            <div class="cell-1">
              <el-checkbox @change="handleCheckChange(item.id,item.isCheck)" v-model="item.isCheck"></el-checkbox>
            </div>
            <div class="cell-2">
              <div class="cell-2-box" v-cloak>
                <div class="mask">
                  <div class="mask-1" v-if="!item.spu.isShelves">已下架</div>
                  <img :src="$oucy.ossUrl+item.spu.cover" alt class="goods-img" />
                </div>
                <span
                  class="goods-txt2"
                  :style="!item.spu.isShelves?'color:#DDD':''"
                >{{item.spu.spuName}}</span>
              </div>
            </div>
            <div
              class="cell-3 goods-txt3"
              :style="!item.spu.isShelves?'color:#DDD':''"
            >￥{{item.spu.spuMinPrise}}</div>
            <div
              class="cell-4 goods-txt4"
              :style="!item.spu.isShelves?'color:#DDD':''"
            >{{item.spu.spuCollect}}</div>
            <div class="cell-5 goods-txt5">
              <div class="cell-5-box">
                <el-button
                  size="mini"
                  class="cell-5-btn"
                  :style="item.collectTopping?'background:#2090FF;color:#FFFFFF':''"
                  @click="collectToppingFn(item.id)"
                >
                  <div>{{item.collectTopping?'取消置顶':'置顶'}}</div>
                </el-button>
                <el-button
                  size="mini"
                  class="cell-5-btn"
                  @click="$oucy.go('/product?spuId='+item.collectId)"
                >
                  <div>分享</div>
                </el-button>
                <el-button size="mini" class="cell-5-btn" @click="isCollectFn(item.id)">
                  <div>删除</div>
                </el-button>
              </div>
            </div>
          </div>
          <div class="link"></div>
        </div>
      </div>
      <div class="bottom-cell-1">
        <div class="bottom-cell-2">
          <div class="bottom-select">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          </div>

          <div class="bottom-btn-box">
            <!-- <el-button size="mini" class="bottom-btn">
              <div>分享</div>
            </el-button>-->
            <el-button size="mini" class="bottom-btn" @click="someDelFn()">
              <div>删除</div>
            </el-button>
          </div>
        </div>
        <div>
          <div class="pagination" style="background-color: #ffffff;padding: 20px 0;">
            <el-pagination
              v-if="true"
              background
              layout="sizes, prev, pager, next, jumper, ->, total, slot"
              :page-size="10"
              :total="totalElements"
              @current-change="currentChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="collection-not">
      <img src="@/assets/images/photo/collection_not.png" />
      <div class="c-2 m-t-50 f14">您还未收藏过任何商品/店铺/个人名片/企业名片~</div>
    </div>
  </div>
</template>

<script>
import { collectuser, scence } from "@/service";
export default {
  created() {
    // console.log("挂载商品");
    this.getCollectGoods();
    this.$parent.selectTabID = 1;
  },
  data() {
    return {
      checkAll: false,
      goodsList: [], //收藏商品数据
      checkList:[], //多选框数据
      collectId: { id: "" }, //置顶参数
      iscollectId: { id: "", collectType: 2 }, //删除的参数
      someDelId: [],
      paramsPoster: { spuId: "", posterType: "" }, //分享的参数
      totalElements: 0,
      paramsGoods: { collectType: 2, limit: 10, start: 0 } //请求收藏商品参数 collectType:1企业 2商品
    };
  },
  methods: {
    // 请求：收藏的商品
    getCollectGoods(obj) {
      obj = this.paramsGoods;
      collectuser.getCollectType(obj).then(res => {
        let { content, totalElements } = res;
        //商品数据增加isCheck字段
        this.goodsList = content&&content.map(item=>{
          item.isCheck = false
          return item
        });
        //商品
        this.goodsList = content;
        //总页数
        this.totalElements = totalElements;
      });
    },
    //商品单选框
    handleCheckChange(id,value) {
      console.log('value',value);
      console.log('id',id);
      if (value) {
        this.checkList.push(id)
      }else {
        let n = this.checkList.indexOf(id)
        this.checkList.splice(n,1)
      }
        console.log(this.checkList);
        this.checkAll = this.checkList.length === this.goodsList.length
    },
    //商品全选框
    handleCheckAllChange(val) {
      this.goodsList.forEach(item=>{
          item.isCheck = val
        });
      if (val) {
        this.checkList = []
        this.goodsList.forEach(item => {
        this.checkList.push(item.id); 
      })
      }else{
        this.checkList = []
      }
        console.log("全选数组",this.checkList);
    },
    // 分页器:
    // 第几页
    currentChange(page) {
      this.paramsGoods.start = page - 1;
      collectuser.getCollectType(this.paramsGoods).then(res => {
        let { content } = res;
        // console.log(content);
        this.goodsList = content;
      });
    },
    //置顶
    collectToppingFn(id) {
      this.collectId.id = id;
      //点击置顶
      collectuser.collectTopping(this.collectId);
      //刷新页面
      this.getCollectGoods(this.paramsGoods);
    },
    //删除
   async isCollectFn(id) {
      this.iscollectId.id = id;
    // await  collectuser.delCollectUser(this.iscollectId);
     await  collectuser.batchDelCollectUser({ids:JSON.stringify([id])});
      //刷新页面
      this.getCollectGoods(this.paramsGoods);
    },
    //多选删除
   async someDelFn() {
      console.log("checkList",this.checkList);
      //全选删除
    await  collectuser.batchDelCollectUser({ids:JSON.stringify(this.checkList)})
       //刷新页面
      this.getCollectGoods(this.paramsShop);
      this.checkList=[]
    }
  }
};
</script>

<style lang="less" scoped>
[v-cloak] {
  display: none !important;
}
.collection-not{
  margin-top: 100px;
}
.link {
  border-top: 1px solid #f6f7f8;
  margin-top: 10px;
  margin-left: 20px;
}
.collectionNotImg {
  width: 100%;
  display: block;
  margin: 21px auto;
  .cell-1 {
    flex: 1;
  }

  .cell-2 {
    .cell-2-box {
      display: flex;
      .mask {
        position: relative;
        .mask-1 {
          color: blanchedalmond;
          position: absolute;
          width: 64px;
          height: 64px;
          border-radius: 2.8px 2.8px 2.8px 2.8px;
          opacity: 0.5;
          background-color: black;
          line-height: 64px;
        }
      }
    }
    flex: 6;
    .goods-txt2 {
      width: 274.4px;
      height: 24.5px;
      line-height: 14px;
      font-size: 12px;
      font-family: MicrosoftYaHei-, MicrosoftYaHei;
      font-weight: normal;
      color: #555c68;
      text-align: left;
      margin: auto;
    }
  }

  .cell-3 {
    flex: 4;
  }
  .cell-4,
  .cell-5 {
    flex: 3;
  }
}
.tableHeader {
  width: 100%;
  display: flex;
  background: #fafafa;
  border-radius: 1.4px;
  padding: 7px;
  box-sizing: border-box;
  font-size: 9.8px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #555c68;
}
.table-item {
  display: flex;
  flex-direction: column;
  .table-boby {
    color: #555c68;
    display: flex;
    justify-content: center;
    align-items: center;
    .goods-img {
      width: 64px;
      height: 64px;
      border-radius: 2.8px 2.8px 2.8px 2.8px;
      opacity: 1;
    }
    .goods-txt3 {
      width: 40px;
      height: 13.3px;
      font-size: 9.8px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: 550;
      // color: #dddddd;
      color: #ed6732;
      line-height: 0px;
      margin: auto;
    }
    .goods-txt4 {
      width: 40px;
      height: 13.3px;
      font-size: 10px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: normal;
      // color: #dddddd;
      line-height: 0 px;
      margin: auto;
    }
    .goods-txt5 {
      .cell-5-box {
        display: flex;
        flex-direction: column;
        margin-left: -18px;
        padding-left: 7px;
        .cell-5-btn {
          margin: 5px auto;
          width: 75px;
          height: 25px;
          background: #f6faff;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          border: 1px solid #cfe0f0;
          div {
            line-height: 5px;
            text-align: center;
          }
        }
      }
    }
    .el-button + .el-button {
      margin-left: 0px;
      margin-top: 5.6px;
      width: 50px;
    }
  }
}
.bottom-cell-1 {
  .bottom-cell-2 {
    .bottom-select {
      margin-left: 25.5px;
    }
    display: flex;
    margin-top: 25px;
  }
  display: flex;
  justify-content: space-between;
  .bottom-btn-box {
    margin-left: 20px;
    .bottom-btn {
      margin: auto 5px;
      width: 68px;
      height: 25.2px;
      background: #f6faff;
      border-radius: 1.4px 1.4px 1.4px 1.4px;
      opacity: 1;
      border: 1 px solid #cfe0f0;
      div {
        line-height: 4.9px;
        width: 22.4px;
        height: 15.2px;
        font-size: 14;
        padding: 3px 0 0 5px;
      }
    }
  }
}
</style>